#UserAgent
_ua = do ->
  {
    ltIE6:typeof(window.addEventListener) is 'undefined' and typeof(document.documentElement.style.maxHeight) is 'undefined',
    ltIE7:typeof(window.addEventListener) is 'undefined' and typeof(document.querySelectorAll) is 'undefined',
    ltIE8:typeof(window.addEventListener) is 'undefined' and typeof(document.getElementsByClassName) is 'undefined',
    IE:document.uniqueID,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit: not document.uniqueID and not window.opera and not window.sidebar and not window.orientation and window.localStorage,
    Mobile:window.orientation
  }

#URL
url = do ->
  href = location.href.split('/')

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val is '' or i is href.length - 1 and val.indexOf('.')
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j is 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing, {
    easeOutCubic: (x,t,b,c,d) ->
      c*((t=t/d-1)*t*t+1)+b
})

#配列ソート用関数（大->小）
high_low = (a,b) ->
  return(b - a)

$(->
  $id = $('body').attr('id')
  $class = $('body').attr('class')
  animateConf = {queue:false,duration:'fast'}

  $('.hover img,.hover input').hover(
    ->
      $(this).stop().animate(
        # if $(this).parents('#header .cnavi').get(0) or $(this).parents('#footer .cnavi').get(0)
        if $(this).parents('.p-nav .cnavi').get(0)
          {opacity:0}
        else
          {opacity:.5}
      ,animateConf
      )
      return
    ,->
      $(this).stop().animate({
        opacity:1
      },animateConf
      )
      return
  )

  i = 1

  $('#footer .wrapper .sitemap > ul > li').each(
    ->
      if i % 3 is 0
        right = $(this).parent('ul').children('li').get(i - 1)
        center = $(this).parent('ul').children('li').get(i - 2)
        left = $(this).parent('ul').children('li').get(i - 3)

        height = new Array

        height[0] = $(right).height()
        height[1] = $(center).height()
        height[2] = $(left).height()

        height.sort(high_low)

        $(right).height(height[0])
        $(center).height(height[0])
        $(left).height(height[0])
      i++

      return
  )

  $('#sub a img').hover(
    ->
      $(this).stop().animate({
        opacity:.5
      },animateConf
      )
      return
    ,->
      $(this).stop().animate({
        opacity:1
      },animateConf
      )
      return
  )

  if $class?
    # if $class.indexOf('faq') isnt -1
    #   $(window).load(
    #     ->
    #       $('#main .section ul li dl dd').each(
    #         ->
    #           $(this).show()
    #           $(this).css('height',$(this).height())
    #           $(this).hide()
    #           return
    #       )
    #
    #       $('#main .section ul li dl .title a').click(
    #         (e)->
    #           $(this).parent('.title').siblings('dd').slideToggle(
    #             ->
    #               if $(this).siblings('.title').children('a').attr('style') is undefined or $(this).siblings('.title').children('a').attr('style').indexOf('close') is -1
    #                 $(this).siblings('.title').children('a').css('backgroundImage','url('+url+'img/faq_q_bg_close.gif)')
    #                 return
    #               else
    #                 $(this).siblings('.title').children('a').css('backgroundImage','url('+url+'img/faq_q_bg_open.gif)')
    #                 return
    #           )
    #
    #           e.preventDefault()
    #           return
    #       )
    #       return
    #   )

    if $id is 'inquiry' or $id is 'secretariat'
      $('form').exValidation({
        rules: {
          name:'chkrequired',
          email:'chkrequired chkemail',
          emailcheck:'chkrequired chkemail chkretype-email'
          text:'chkrequired'
        },
        errMsgPrefix:'※',
        errZIndex:1100
      })

    if $id is 'admission'
      $('form').exValidation({
        rules: {
          name:'chkrequired',
          email:'chkrequired chkemail',
          emailcheck:'chkrequired chkemail chkretype-email'
        },
        errMsgPrefix:'※',
        errZIndex:1100
      })

    if $class.indexOf('activities') isnt -1 or $class.indexOf('medical') isnt -1
      $('#main .section #no8892 .document .link a').click(
        (e)->
          $.fancybox([
            url+'img/activities_conference_no8892_pic1.gif',
            url+'img/activities_conference_no8892_pic2.gif',
            url+'img/activities_conference_no8892_pic3.gif'
          ], {
            'padding':0,
            'type':'image',
          })
          e.preventDefault()
          return
      )

      $('#main .section #no8903 .link a').fancybox()

      $('#main .section #no8904 .document .link a').click(
        (e)->
          $.fancybox([
            url+'img/activities_conference_no8904_pic1.gif',
            url+'img/activities_conference_no8904_pic2.gif',
            url+'img/activities_conference_no8904_pic3.gif',
            url+'img/activities_conference_no8904_pic4.gif'
          ], {
            'padding':0,
            'type':'image',
          })
          e.preventDefault()
          return
      )
      return

    if $id is 'misunderstanding'
      $('.anchor-item').find('a').on('click'
        (e)->
          e.preventDefault()

          $(
            if (navigator.userAgent.indexOf('Opera') isnt -1)
            then (
              if document.compatMode is 'BackCompat'
              then 'body'
              else 'html'
            )
            else 'html,body'
          ).animate({
            scrollTop:$($(this).attr('href')).offset().top - 20
          },{
            easing:'easeOutCubic',
            duration:1000
          })
      )

  else
    if $id is 'index'
      $tab = $('#activities .tab li')

      $(window).load(
        ->
          $('#activities .wrapper').height($('#activities .wrapper .active').height())

          $tab.on('click',
            (e)->
              if not $(this).hasClass('active')
                $(this).stop().animate({
                  opacity:1
                },animateConf
                )

                $(this).parent('.tab').children('li').removeClass('active')
                $(this).addClass('active')

                $tabImg = $(this).parent('.tab').children('li').children('a').children('img')

                $tabImg.each(
                  ->
                    $(this).attr('src',$(this).attr('src').replace('_a.gif','.gif'))
                    return
                )

                $(this).children('a').children('img').attr('src',$(this).children('a').children('img').attr('src').replace('.gif','_a.gif'))

                $contents = $(this).parent('.tab').siblings('.wrapper').children('div')
                $target = $($(this).children('a').attr('href'))

                $contents.removeClass('active').hide()
                $target.addClass('active').fadeIn()
                $target.parent('.wrapper').height($target.height())

              e.preventDefault()
              return
          )

          $tab.hover(
            ->
              if not $(this).hasClass('active')
                $(this).stop().animate({
                  opacity:.5
                },animateConf
                )
                return
            ,->
              if not $(this).hasClass('active')
                $(this).stop().animate({
                  opacity:1
                },animateConf
                )
                return
          )

          $hoverTarget = $('#crf ul li dl dt,#crf ul li dl .text,#effort ul li dl dt a img,#activities ol li a img')

          $hoverTarget.hover(
            ->
              if $(this).parents('#crf').get(0)
                $target = $(this).parent('dl').children('dt')
              else
                $target = $(this)

              $target.stop().animate({
                opacity:.5
              },animateConf
              )
              return
            ,->
              if $(this).parents('#crf').get(0)
                $target = $(this).parent('dl').children('dt')
              else
                $target = $(this)

              $target.stop().animate({
                opacity:1
              },animateConf
              )
              return
          )
          return
      )

  # $('.js-headroom').headroom({
  #   offset: $('#header').outerHeight() - $('.js-headroom').outerHeight()
  # })
  # return
)
